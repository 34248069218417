import { Box, Alert, Typography } from '@mui/material';

import React from 'react';

interface CustomAlertProps {
  severity: 'error' | 'warning' | 'info' | 'success';
  message: string;
}

const CustomAlert: React.FC<CustomAlertProps> = ({ severity, message }) => {
  return (
    <Box>
      <Alert severity={severity} sx={{ marginBottom: '20px' }}>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20.02px',
            letterSpacing: '0.17px',
            textAlign: 'left',
          }}
        >
          {message}
        </Typography>
      </Alert>
    </Box>
  );
};

export default CustomAlert;
