import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface DynamicDialogProps {
  isOpen: boolean;
  icon: string;
  title: string;
  message: string;
  buttonText: string;
  onClose: () => void;
  routeOnClose?: string;
  onLogout?: () => void | null;
}

const DynamicDialog: React.FC<DynamicDialogProps> = ({ isOpen, icon, title, message, buttonText, onClose, routeOnClose, onLogout }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
    if (routeOnClose) navigate(routeOnClose);
    if (onLogout) onLogout();
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth='md' sx={{ width: '100%' }}>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <img src={icon} alt='Icon' />
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '38.4px',
            letterSpacing: '0.15px',
            textAlign: 'center',
            color: '#000000DE',
            marginTop: '20px',
          }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '27px',
            letterSpacing: '0.15px',
            textAlign: 'center',
            color: '#000000DE',
          }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button onClick={handleClose}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.4px',
              color: '#0048B6',
            }}
          >
            {buttonText}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DynamicDialog;
