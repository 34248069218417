import { Avatar } from '@mui/material';

interface InitialsAvatarProps {
  name: string;
  size?: number;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  style?: React.CSSProperties;
  variant: 'circular' | 'rounded' | 'square';
}

const getInitials = (name: string): string => {
  const nameParts = name.split(' ');
  if (nameParts.length > 1) {
    return `${nameParts[0][0]}${nameParts[1][0]}`.toUpperCase();
  }
  return name[0];
};

const InitialsAvatar: React.FC<InitialsAvatarProps> = ({ name, size = 40, onClick, style, variant }) => {
  return (
    <Avatar onClick={onClick} sx={{ width: size, height: size, bgcolor: '#00000061', ...style }} variant={variant}>
      {getInitials(name)}
    </Avatar>
  );
};

export default InitialsAvatar;
