import React from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { institutionCardStyles } from '.';
import InstitutionCardStatus from './InstitutionCardStatus';
import InstitutionCardActions from './InstitutionCardActions';
import { formatCNPJ } from 'services/utils/cnpj.utils';

interface InstitutionCardProps {
  uuid: string;
  profile: string;
  city: string;
  name: string;
  fantasyName: string;
  identification: string;
  statusList: string[];
  isChecked: boolean;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlockUser: () => void;
  blockDisabled: boolean;
}

const InstitutionCard: React.FC<InstitutionCardProps> = ({
  uuid,
  profile,
  city,
  name,
  fantasyName,
  identification,
  statusList,
  isChecked,
  onCheckboxChange,
  // onBlockUser,
  // blockDisabled,
}) => {
  return (
    <Box sx={institutionCardStyles.container}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <Checkbox checked={isChecked} onChange={onCheckboxChange} />
        </Box>

        <Box sx={institutionCardStyles.details}>
          <Typography sx={{ color: '#000000DE', fontWeight: 500, fontSize: '18px', lineHeight: '28.26px', letterSpacing: '0.1px' }}>{fantasyName}</Typography>
          <Typography sx={{ color: '#00000099', fontWeight: 400, fontSize: '16px', lineHeight: '26.56px', letterSpacing: '0.4px' }}>
            {`CNPJ: ${formatCNPJ(identification)}`}
          </Typography>
          <Typography sx={{ color: '#00000099', fontWeight: 400, fontSize: '16px', lineHeight: '26.56px', letterSpacing: '0.4px' }}>
            {`Município: ${city}`}
          </Typography>
          <Typography sx={{ color: '#00000099', fontWeight: 400, fontSize: '16px', lineHeight: '26.56px', letterSpacing: '0.4px' }}>
            {`Razão Social: ${name}`}
          </Typography>
          <Typography sx={{ color: '#00000099', fontWeight: 400, fontSize: '16px', lineHeight: '26.56px', letterSpacing: '0.4px' }}>
            {`Perfil: ${profile}`}
          </Typography>
        </Box>
        <InstitutionCardActions uuid={uuid} />
      </Box>

      <InstitutionCardStatus statusList={statusList} />
    </Box>
  );
};

export default InstitutionCard;
