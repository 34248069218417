export const MSG_DATA_ERROR = 'Erro de dados';
export const MSG_INSTITUTION_ID_NOT_FOUND = 'ID da instituição não encontrado';
export const MSG_INSTITUTION_UPDATED = 'Instituição Alterada';
export const MSG_INSTITUTION_WAS_UPDATED = 'A instituição foi alterada com sucesso';
export const MSG_GROUP_ID_NOT_FOUND = 'ID do grupo não encontrado';
export const MSG_HELPER_CELLPHONE = 'Exemplo: +55 (##) #####-####';
export const MSG_NEXT = 'Avançar';
export const MSG_BACK = 'Voltar';
export const MSG_SUBMIT = 'Enviar';
export const MSG_RESET = 'Resetar';
export const MSG_FINISH = 'Concluir';
