import React, { useState } from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

interface UserCardActionsProps {
  onBlockUser: () => void;
  blockDisabled: boolean;
  userUuid: string;
  openManageTermDialog: () => void;
  usersWithTermValidity: boolean;
}

const UserCardActions: React.FC<UserCardActionsProps> = ({ onBlockUser, blockDisabled, userUuid, openManageTermDialog, usersWithTermValidity }) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ marginLeft: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: '16px', paddingTop: '8px' }}>
      <Button variant='outlined' endIcon={<ArrowDropDownIcon />} sx={{ color: '#0048B6', border: '1px solid #0048B6', fontSize: '15px' }} onClick={handleClick}>
        Ações
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => navigate(ROUTES.USER_VIEW.replace(':uuid', userUuid))} sx={{ fontSize: '15px' }}>
          Visualizar
        </MenuItem>
        <MenuItem onClick={() => navigate(ROUTES.USER_EDIT.replace(':uuid', userUuid))} sx={{ fontSize: '15px' }}>
          Editar
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onBlockUser();
          }}
          sx={{ fontSize: '15px' }}
          disabled={blockDisabled}
        >
          Bloquear
        </MenuItem>
        {usersWithTermValidity && (
          <MenuItem
            onClick={() => {
              handleClose();
              openManageTermDialog();
            }}
            sx={{ fontSize: '15px' }}
          >
            Gerenciar Vigência
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default UserCardActions;
