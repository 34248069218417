export const validateCellphone = (value: string): boolean => {
  if (!value) return false;
  const cleaned = value.replace(/\D/g, '');
  return cleaned.length === 13;
};

export const formatPhoneNumber = (value: string): string => {
  // Remove tudo que não for número, exceto o "+"
  const onlyNumbers = value.replace(/[^\d+]/g, '');

  // Usa regex para organizar o código do país, DDD e número
  return onlyNumbers.replace(/^(\+\d{2})(\d{2})(\d{5})(\d{4})$/, '$1 ($2) $3-$4');
};
