const cardStyles = {
  container: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '16px',
  },
  details: { display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', alignContent: 'flex-start', alignItems: 'flex-start' },
};

export default cardStyles;
