import React, { useState } from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface UserTermCardActionsProps {
  idTerm: string;
  onEdit: (id: string) => void;
}

const UserTermCardActions: React.FC<UserTermCardActionsProps> = ({ idTerm, onEdit }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    onEdit(idTerm);
    handleClose();
  };

  return (
    <Box sx={{ marginLeft: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: '16px', paddingTop: '8px' }}>
      <Button variant='outlined' endIcon={<ArrowDropDownIcon />} sx={{ color: '#0048B6', border: '1px solid #0048B6', fontSize: '15px' }} onClick={handleClick}>
        Ações
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleEditClick} sx={{ fontSize: '15px' }}>
          Alterar Vigência
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserTermCardActions;
