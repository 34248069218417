import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { getTokenMtLogin, getUserInfoMtLogin } from 'services/auth/apiService';
import { authService, UserDataStorage } from 'services/auth/authService';
import { getURLMTLogout } from 'services/auth/config';

export enum AuthStatus {
  requestError = 'REQUEST_ERROR',
  sessionExpired = 'SESSION_EXPIRED',
  userNotFound = 'USER_NOT_FOUND',
  userBlocked = 'USER_BLOCKED',
  userInactive = 'USER_INACTIVE',
  userAcceptTerms = 'USER_ACCEPT_TERMS',
  userAuthorized = 'USER_AUTHORIZED',
}

interface AuthContextData {
  user: UserDataStorage | null;
  isAuthenticated: boolean;
  isSessionValidating: boolean;
  authenticateWithMtLogin: (code: string) => Promise<{ success: boolean; status: AuthStatus; message?: string }>;
  validateExistingSession: () => Promise<{ success: boolean; status: AuthStatus; message?: string }>;
  checkAuthStatus: () => Promise<{ success: boolean; status: AuthStatus; message?: string }>;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserDataStorage | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSessionValidating, setIsSessionValidating] = useState(true);

  const validateExistingSession = useCallback(async () => {
    setIsSessionValidating(true);

    const token = authService.getMtLoginTokeLocalStorage();

    if (!token) {
      setIsSessionValidating(false);
      return { success: false, status: AuthStatus.sessionExpired, message: 'Failed to get token from MtLogin' };
    }

    const userInfoResult = await getUserInfoMtLogin(token);

    if (!userInfoResult.userInfo) {
      setIsSessionValidating(false);
      return { success: false, status: AuthStatus.sessionExpired, message: userInfoResult.error?.error_description };
    }

    const userStatusResult = await authService.checkUserStatusByCpf(userInfoResult.userInfo.cpf);

    if (userStatusResult.success) {
      setUser(userStatusResult.userInfo!);
      setIsAuthenticated(true);
      authService.setUserDataLocalStorage(userStatusResult.userInfo!);

      setIsSessionValidating(false);
      if (!userStatusResult.userInfo!.hasAcceptedTerms) {
        return { success: true, status: AuthStatus.userAcceptTerms };
      }

      return { success: true, status: AuthStatus.userAuthorized };
    } else {
      return userStatusResult;
    }
  }, []);

  const authenticateWithMtLogin = useCallback(
    async (code: string) => {
      try {
        const tokenResult = await getTokenMtLogin(code);

        if (!tokenResult.accessToken) {
          return { success: false, status: AuthStatus.sessionExpired, message: 'Failed to get token from MtLogin' };
        }

        authService.setMtLoginTokeLocalStorage(tokenResult.accessToken);

        return await validateExistingSession();
      } catch (error) {
        return { success: false, status: AuthStatus.sessionExpired, message: 'Authentication failed' };
      }
    },
    [validateExistingSession],
  );

  const checkAuthStatus = useCallback(async () => {
    setIsSessionValidating(true);
    const token = authService.getMtLoginTokeLocalStorage();

    if (!token) {
      setIsSessionValidating(false);
      return { success: false, status: AuthStatus.sessionExpired, message: 'Sessão expirada' };
    }

    const userInfoResult = await getUserInfoMtLogin(token);

    if (!userInfoResult.userInfo) {
      setIsSessionValidating(false);
      return { success: false, status: AuthStatus.sessionExpired, message: userInfoResult.error?.error_description };
    }

    const userStatusResult = await authService.checkUserStatusByCpf(userInfoResult.userInfo.cpf);

    if (userStatusResult.success) {
      setUser(userStatusResult.userInfo!);
      setIsAuthenticated(true);
      authService.setUserDataLocalStorage(userStatusResult.userInfo!);
      setIsSessionValidating(false);
      return { success: true, status: AuthStatus.userAuthorized };
    } else {
      setIsSessionValidating(false);
      return userStatusResult;
    }
  }, []);

  const logout = useCallback(() => {
    authService.removeToken();
    setUser(null);
    setIsAuthenticated(false);
    window.location.href = getURLMTLogout();
  }, []);

  useEffect(() => {
    validateExistingSession();
  }, [validateExistingSession]);

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, isSessionValidating, authenticateWithMtLogin, validateExistingSession, checkAuthStatus, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextData => {
  return useContext(AuthContext);
};
