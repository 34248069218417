import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto',
    h1: { fontSize: '32px', fontWeight: 500, lineHeight: '51.2px', letterSpacing: '0.15px' },
    h2: { fontSize: '24px', fontWeight: 700, lineHeight: '38.4px', letterSpacing: '0.15px' },
    h5: { fontSize: '24px', fontWeight: 400, lineHeight: '32.02px' },
    h6: { fontSize: '20px', fontWeight: 500, lineHeight: '32px', letterSpacing: '0.15px' },
    body1: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '36px',
      letterSpacing: '0.15px',
      textAlign: 'center',
    },
    body2: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '27px',
      letterSpacing: '0.15px',
    },
    overline: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '31.92px',
      letterSpacing: '1px',
    },
    subtitle2: { fontSize: '14px', fontWeight: 600, lineHeight: '21.98px', letterSpacing: '0.1px' },
  },
  components: {
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.15px',
          textAlign: 'left',
        },
        secondary: {
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '21px',
          letterSpacing: '0.15px',
          textAlign: 'left',
          color: '#000000DE',
        },
      },
    },
  },
});

export default theme;
