import { Box, SxProps, Theme } from '@mui/system';
import CustomAccordion from 'components/Shared/CustomAccordion';
import { InstitutionStatusEnum } from 'common/enums';
import { IModel } from './types.form';
import { CustomLabel } from 'components/Shared';

const InstitutionFormStepTwo: React.FC<Pick<IModel, 'state'>> = ({ state }) => {
  return (
    <Box sx={{ mx: '32px', mt: '32px' }}>
      <CustomAccordion accordionSummaryText={'Dados cadastrais'}>
        <Box>
          <Box sx={lineStyle}>
            <Box sx={columnStyle}>
              <CustomLabel text='Nome Fantasia' labelStyle={labelStyle} />
              <CustomLabel text={state?.institution?.fantasyName as string} labelStyle={valueStyle} />
            </Box>

            <Box sx={columnStyle}>
              <CustomLabel text='CNPJ' labelStyle={labelStyle} />
              <CustomLabel text={state?.institution?.identification as string} labelStyle={valueStyle} />
            </Box>
          </Box>

          <Box sx={lineStyle}>
            <Box sx={columnStyle}>
              <CustomLabel text='Razão Social' labelStyle={labelStyle} />
              <CustomLabel text={state?.institution?.name as string} labelStyle={valueStyle} />
            </Box>

            <Box sx={columnStyle}>
              <CustomLabel text='CEP' labelStyle={labelStyle} />
              <CustomLabel text={state?.institution?.address.cep as string} labelStyle={valueStyle} />
            </Box>
          </Box>

          <Box sx={lineStyle}>
            <Box sx={columnStyle}>
              <CustomLabel text='Endereço' labelStyle={labelStyle} />
              <CustomLabel text={state?.institution?.address.street as string} labelStyle={valueStyle} />
            </Box>

            <Box sx={columnStyle}>
              <CustomLabel text='Número' labelStyle={labelStyle} />
              <CustomLabel text={state?.institution?.address.number as string} labelStyle={valueStyle} />
            </Box>
          </Box>

          <Box sx={lineStyle}>
            <Box sx={columnStyle}>
              <CustomLabel text='Bairro' labelStyle={labelStyle} />
              <CustomLabel text={state?.institution?.address.district as string} labelStyle={valueStyle} />
            </Box>

            <Box sx={columnStyle}>
              <CustomLabel text='Município' labelStyle={labelStyle} />
              <CustomLabel text={state?.institution?.address.city as string} labelStyle={valueStyle} />
            </Box>
          </Box>

          <Box sx={lineStyle}>
            <Box sx={columnStyle}>
              <CustomLabel text='Email' labelStyle={labelStyle} />
              <CustomLabel text={state?.institution?.email as string} labelStyle={valueStyle} />
            </Box>
          </Box>

          <Box sx={lineStyle}>
            {state?.institution?.contactList.map((contact, index) => (
              <>
                <Box key={index} sx={columnStyle}>
                  <CustomLabel text={`Telefone ${index + 1}`} labelStyle={labelStyle} />
                  <CustomLabel text={contact.value} labelStyle={valueStyle} />
                </Box>
              </>
            ))}
          </Box>
        </Box>
      </CustomAccordion>

      <CustomAccordion accordionSummaryText={'Dados administrativos'}>
        <Box>
          <Box sx={lineStyle}>
            <Box sx={columnStyle}>
              <CustomLabel text='Grupo da Instituição' labelStyle={labelStyle} />
              <CustomLabel text={state?.institution?.group.name as string} labelStyle={valueStyle} />
            </Box>

            <Box sx={columnStyle}>
              <CustomLabel text='Perfil da Instituição' labelStyle={labelStyle} />
              <CustomLabel text={state?.institution?.profile.name as string} labelStyle={valueStyle} />
            </Box>
          </Box>

          <Box sx={lineStyle}>
            <Box sx={columnStyle}>
              <CustomLabel text='Possui CAF jurídica ativa (Obs.: Antiga DAP jurídica)?' labelStyle={labelStyle} />
              <CustomLabel text={state?.institution?.caf ? 'Sim' : 'Não'} labelStyle={valueStyle} />
            </Box>
          </Box>

          <Box sx={lineStyle}>
            <Box sx={columnStyle}>
              <CustomLabel text='Cadastro Ativo?' labelStyle={labelStyle} />
              <CustomLabel
                text={state?.institution?.statusList.map((status) => status.description).includes(InstitutionStatusEnum.REGISTER_ENABLED) ? 'Sim' : 'Não'}
                labelStyle={valueStyle}
              />
            </Box>

            <Box sx={columnStyle}>
              <CustomLabel text='Bloquear Cadastro?' labelStyle={labelStyle} />
              <CustomLabel
                text={state?.institution?.statusList.map((status) => status.description).includes(InstitutionStatusEnum.REGISTER_BLOCKED) ? 'Sim' : 'Não'}
                labelStyle={valueStyle}
              />
            </Box>
          </Box>
        </Box>
      </CustomAccordion>
    </Box>
  );
};

const lineStyle: SxProps<Theme> | undefined = {
  display: 'flex',
};

const columnStyle: SxProps<Theme> | undefined = {
  width: '50%',
  padding: '8px',
};

const labelStyle = {
  color: '#00000099',
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20.02px',
  letterSpacing: '0.17px',
  textAlign: 'left',
  mt: '16px',
};

const valueStyle = {
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  textAlign: 'left',
};

export default InstitutionFormStepTwo;
