import React from 'react';
import { Box } from '@mui/material';
import { MenuProvider } from 'contexts/menu/MenuContext';
import { TopNavBar } from 'components/TopNavBar';
import { Header } from 'components/Home';
import avatarUser from '../../assets/images/avatar-user.png';
import { ModuleCard } from 'components/Shared';
import { ROUTES } from 'routes/constants';

const MenuUser: React.FC = () => {
  return (
    <MenuProvider>
      <TopNavBar />
      <Box
        sx={{
          backgroundColor: '#0048B6',
          marginTop: '10px',
          height: '65px',
          alignContent: 'center',
        }}
      >
        <Header title='Gestão de usuários' showBackButton={true} />
        <Box
          sx={{
            backgroundColor: '#0048B6',
            height: '67px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '25px',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#FFFFFF',
              width: '90%',
              maxWidth: '1152px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '290px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              gap: '15px',
            }}
          >
            <ModuleCard title='Usuários' description='Visualize e gerencie todos os usuários do sistema.' image={avatarUser} path={ROUTES.MANAGE_USER} />
            <ModuleCard
              title='Perfis de usuário'
              description='Visualize e gerencie todos os perfis de usuário disponíveis no sistema.'
              image={avatarUser}
              path={null}
            />
            <ModuleCard
              title='Termos de uso e confidencialidade'
              description='Visualize e gerencie todos os termos de uso e confidencialidade do sistema.'
              image={avatarUser}
              path={null}
            />
          </Box>
        </Box>
      </Box>
    </MenuProvider>
  );
};

export default MenuUser;
