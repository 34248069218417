import axios from 'axios';
import { IUserTerms } from 'types/ApiPermissionResponses';

const BASE_API_URL = process.env.REACT_APP_URL_API ?? '';
const API_URL = BASE_API_URL + '/permissions';

export const getPermissionsDropdown = async (profileUuid?: string): Promise<any> => {
  const response = await axios.get(`${API_URL}/dropdown`, {
    params: profileUuid ? { profileUuid } : {},
  });

  return response.data;
};

export const getPermission = async (permissionUuid: string): Promise<any> => {
  const response = await axios.get(`${API_URL}/uuid/${permissionUuid}`);

  return response.data;
};

export const getUserTerms = async (userUuid: string): Promise<any> => {
  const response = await axios.get(`${API_URL}/manage-user-term/${userUuid}`);

  return response.data;
};

export const addUserPermissionTerm = async (userUuid: string, term: IUserTerms): Promise<any> => {
  const response = await axios.post<any>(`${API_URL}/manage-user-term/${userUuid}`, term);
  return response.data;
};

export const editUserPermissionTerm = async (userUuid: string, term: IUserTerms): Promise<any> => {
  const response = await axios.patch<any>(`${API_URL}/manage-user-term/${userUuid}/${term.id}`, term);
  return response.data;
};
