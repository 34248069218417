import { AppBar, Box, styled } from '@mui/material';

export const CustomSecondaryAppBar = styled(AppBar)({
  height: '60px',
  backgroundColor: '#ffffff',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 16px',
  position: 'relative',
});

export const RightContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
});

export const LogoContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});
