import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import RoutesConfig from './routes';
import UnexpectedError from './resources/UnexpectedError';
import { CircularProgress } from '@mui/material';
import ProtectedRoute from 'contexts/auth/ProtectedRoute';
import { AuthProvider } from 'contexts/auth/AuthContext';

const Root: React.FC = () => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ErrorBoundary FallbackComponent={UnexpectedError}>
        <AuthProvider>
          <Routes>
            {RoutesConfig.map(({ path, element: Element, protectedRoute, ...args }) => {
              return protectedRoute ? (
                <Route key={path} path={path} element={<ProtectedRoute element={<Element />} />} {...args} />
              ) : (
                <Route key={path} path={path} element={<Element />} {...args} />
              );
            })}
          </Routes>
        </AuthProvider>
      </ErrorBoundary>
    </Suspense>
  );
};

export default Root;
