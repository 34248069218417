import { AppBar, Box, Button, styled, Toolbar } from '@mui/material';

export const CustomButton = styled(Button)({
  fontFamily: 'Roboto',
  fontSize: '13px',
  fontWeight: 500,
  lineHeight: '22px',
  letterSpacing: '0.46px',
  textAlign: 'left',
  color: '#ffffff',
  bottom: '15px',
});

export const CustomAppBar = styled(AppBar)({
  height: '32px',
  backgroundColor: '#0048B6',
  position: 'relative',
});

export const CustomToolbar = styled(Toolbar)({
  height: '100%',
  minHeight: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const LeftButtons = styled(Box)({
  display: 'flex',
  gap: '16px',
});

export const RightButtons = styled(Box)({
  display: 'flex',
  gap: '0px',
});

export const CustomRightButton = styled(CustomButton)({
  marginRight: '-10px',
});
