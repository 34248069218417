import { styled, RadioGroup } from '@mui/material';

export const CustomRadioGroup = styled(RadioGroup)(() => ({
  flex: 1,
  '& .MuiFormLabel-root': {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    color: '#00000099',
    marginBottom: '8px',
  },
  '& .MuiFormControlLabel-label': {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 400,
    color: '#000000',
  },
}));

export default CustomRadioGroup;
