export const ROUTES = {
  LOGIN: '/',
  LOGIN_VALIDATION: '/valida-login',
  TERMS_OF_USE_AND_CONFIDENTIALITY: '/termo',
  HOME: '/painel',
  MENU_USER: '/menu-usuario',
  MANAGE_USER: '/usuarios',
  USER_CREATE: '/usuarios/cadastrar',
  USER_EDIT: '/usuarios/editar/:uuid',
  USER_VIEW: '/usuarios/visualizar/:uuid',
  INSTITUTION: '/instituicoes',
  INSTITUTION_VIEW: '/instituicoes/visualizar/:uuid',
  INSTITUTION_EDIT: '/instituicoes/editar/:uuid',
};
