import React from 'react';
import { Typography } from '@mui/material';

interface CustomLabelProps {
  text: string;
  labelStyle: {
    color?: string;
    fontFamily: string;
    fontSize: string;
    fontWeight: number;
    lineHeight: string;
    letterSpacing: string;
    textAlign: string;
  };
}

const CustomLabel: React.FC<CustomLabelProps> = ({ text, labelStyle }) => {
  return <Typography sx={labelStyle}>{text}</Typography>;
};

export default CustomLabel;
