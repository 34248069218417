import React from 'react';
import { Box, Chip } from '@mui/material';

interface ChipGroupProps {
  chips: string[];
}

const ChipGroup: React.FC<ChipGroupProps> = ({ chips }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
      {chips.map((chip) => (
        <Chip
          key={chip}
          sx={{
            backgroundColor: '#EAEFF9',
            height: '22px',
            borderRadius: '4px 0px 0px 0px',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '14px',
            letterSpacing: '0.15px',
            textAlign: 'left',
            margin: '0px 5px',
          }}
          label={chip}
        />
      ))}
    </Box>
  );
};

export default ChipGroup;
