import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { InstitutionStatusEnum } from 'common/enums';

interface InstitutionCardStatusProps {
  statusList: string[];
}

const InstitutionCardStatus: React.FC<InstitutionCardStatusProps> = ({ statusList }) => {
  const getStatusStyles = () => {
    const observer: {
      [key: string]: () => { icon: JSX.Element | null; text: string; backgroundColor: string };
    } = {
      [InstitutionStatusEnum.REGISTER_ENABLED]: () => ({
        icon: <CheckCircleOutlineIcon sx={{ color: '#2E7D32', fontSize: '20px' }} />,
        text: 'Situação: Ativo',
        backgroundColor: '#EAF2EA',
      }),
      [InstitutionStatusEnum.REGISTER_BLOCKED]: () => ({
        icon: <ErrorOutlineOutlinedIcon sx={{ color: '#D56565', fontSize: '20px' }} />,
        text: 'Situação: Inativo',
        backgroundColor: '#FDF7F7',
      }),
      default: () => ({
        icon: null,
        text: '',
        backgroundColor: '#E0E0E0', // Cinza claro para desconhecido
      }),
    };

    let statusInObserverList = statusList.filter((status) => Object.keys(observer).includes(status));

    if (statusInObserverList.length === 0) statusInObserverList = ['default'];

    const styles = statusInObserverList.map((status) => observer[status]());

    return styles;
  };

  const statusStyles = getStatusStyles();

  return (
    <>
      {statusStyles.map((style, index) => {
        return (
          <Box
            key={index}
            sx={{
              width: '100%',
              backgroundColor: style.backgroundColor,
              padding: '8px',
              borderRadius: '4px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {style.icon}
            <Typography sx={{ color: '#00000099', fontWeight: 4000, fontSize: '16px', lineHeight: '26.56px', letterSpacing: '0.4px', paddingLeft: '8px' }}>
              {style.text}
            </Typography>
          </Box>
        );
      })}
    </>
  );
};

export default InstitutionCardStatus;
