import { Box, Typography } from '@mui/material';
import { userCardStyles, UserTermCardActions, UserTermCardStatus } from '.';
import { IUserTerms } from 'types/ApiPermissionResponses';
import { formatDateToDisplay } from 'utils/dateUtils';

interface UserTermCardProps {
  termData: IUserTerms;
  onEdit: (id: string) => void;
}

const UserTermCard: React.FC<UserTermCardProps> = ({ termData, onEdit }) => {
  return (
    <Box sx={{ ...userCardStyles.container, position: 'relative' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '10px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            alignContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <Typography sx={{ color: '#000000DE', fontWeight: 500, fontSize: '18px', lineHeight: '28.26px', letterSpacing: '0.1px' }}>{termData.id}</Typography>
          <Typography sx={{ color: '#00000099', fontWeight: 400, fontSize: '16px', lineHeight: '26.56px', letterSpacing: '0.4px' }}>
            {`Data inicial: ${formatDateToDisplay(termData.startDate.toString())}`}
          </Typography>
          <Typography sx={{ color: '#00000099', fontWeight: 400, fontSize: '16px', lineHeight: '26.56px', letterSpacing: '0.4px' }}>
            {`Data final: ${termData.endDate ? formatDateToDisplay(termData.endDate.toString()) : ' '}`}
          </Typography>
          <Typography sx={{ color: '#00000099', fontWeight: 400, fontSize: '16px', lineHeight: '26.56px', letterSpacing: '0.4px' }}>
            {`Data final indeterminada: ${termData.endDateIndefinite ? 'Sim' : 'Não'}`}
          </Typography>
          <Box sx={{ width: '100%' }}>
            <UserTermCardStatus status={termData.status} />
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '8px',
            right: '16px',
          }}
        >
          <UserTermCardActions idTerm={termData.id} onEdit={onEdit} />
        </Box>
      </Box>
    </Box>
  );
};

export default UserTermCard;
