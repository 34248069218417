import React from 'react';
import facebookOutlinedIcon from '../../assets/icons/facebook_outlined.svg';
import instagramOutlinedIcon from '../../assets/icons/instagram_outlined.svg';
import twitterFilledIcon from '../../assets/icons/twitter_filled.svg';
import youtubeOutlinedIcon from '../../assets/icons/youtube_outlined.svg';
import { CustomAppBar, CustomToolbar, LeftButtons, CustomButton, RightButtons, CustomRightButton } from './TopLinksBar.styles';

const TopLinksBar: React.FC = () => {
  return (
    <>
      <CustomAppBar>
        <CustomToolbar>
          <LeftButtons>
            <CustomButton>Transparência</CustomButton>
            <CustomButton>Legislação</CustomButton>
            <CustomButton>Secretarias do Governo</CustomButton>
            <CustomButton>Contatos GovMT</CustomButton>
          </LeftButtons>
          <RightButtons>
            <CustomRightButton>
              <img src={facebookOutlinedIcon} />
            </CustomRightButton>
            <CustomRightButton>
              <img src={instagramOutlinedIcon} />
            </CustomRightButton>
            <CustomRightButton>
              <img src={twitterFilledIcon} />
            </CustomRightButton>
            <CustomRightButton>
              <img src={youtubeOutlinedIcon} />
            </CustomRightButton>
          </RightButtons>
        </CustomToolbar>
      </CustomAppBar>
    </>
  );
};

export default TopLinksBar;
