export const formatCNPJ = (value: string): string => {
  const onlyNumbers = value.replace(/\D/g, '');

  if (onlyNumbers.length <= 14) {
    return onlyNumbers
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  }
  return onlyNumbers;
};

export const validateCNPJ = (cnpj: string): boolean => {
  // Remove caracteres não numéricos (pontos, traços, barras)
  cnpj = cnpj.replace(/[^\d]+/g, '');

  // Verifica se o CNPJ tem 14 dígitos
  if (cnpj.length !== 14) return false;

  // Elimina CNPJs com todos os dígitos iguais (ex: 11111111111111)
  if (/^(\d)\1{13}$/.test(cnpj)) return false;

  // Validação do primeiro dígito verificador
  let soma = 0;
  let peso = 5;
  for (let i = 0; i < 12; i++) {
    soma += parseInt(cnpj[i]) * peso;
    peso = peso === 2 ? 9 : peso - 1;
  }

  let resto = soma % 11;
  const digito1 = resto < 2 ? 0 : 11 - resto;

  // Validação do segundo dígito verificador
  soma = 0;
  peso = 6;
  for (let i = 0; i < 13; i++) {
    soma += parseInt(cnpj[i]) * peso;
    peso = peso === 2 ? 9 : peso - 1;
  }

  resto = soma % 11;
  const digito2 = resto < 2 ? 0 : 11 - resto;

  // Verifica se os dígitos calculados são iguais aos dígitos informados
  return digito1 === parseInt(cnpj[12]) && digito2 === parseInt(cnpj[13]);
};
