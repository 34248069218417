import axios from 'axios';

const BASE_API_URL = process.env.REACT_APP_URL_API ?? '';
const API_URL = BASE_API_URL + '/profiles';

export const getProfilesDropdown = async (groupUuid?: string): Promise<any> => {
  const response = await axios.get(`${API_URL}`, {
    params: groupUuid ? { groupUuid } : {},
  });

  return response.data;
};
