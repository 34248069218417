import { criptografar, decriptografar } from 'services/utils/encryption';
import { getUserByCpf } from 'api/user/user.api';
import { AuthStatus } from 'contexts/auth/AuthContext';

const TOKEN_KEY = 'mt_login_token';
const USER_DATA = 'user_data';

export interface UserDataStorage {
  uuid: string;
  name: string;
  cpf: string;
  email: string;
  blockRegistration: boolean;
  activeRegistration: boolean;
  profile: string;
  hasAcceptedTerms: boolean;
}

export const authService = {
  getCodeFromUrl(): string | null {
    const params = new URLSearchParams(window.location.search);
    return params.get('code');
  },

  getMtLoginTokeLocalStorage(): string | null {
    try {
      const encryptedToken = localStorage.getItem(TOKEN_KEY);
      return encryptedToken ? JSON.parse(decriptografar(encryptedToken)) : null;
    } catch (error) {
      console.error('Error retrieving token', error);
      this.removeToken();
      return null;
    }
  },

  setMtLoginTokeLocalStorage(token: string): void {
    try {
      localStorage.setItem(TOKEN_KEY, criptografar(JSON.stringify(token)));
    } catch (error) {
      console.error('Error storing token', error);
    }
  },

  getUserDataLocalStorage(): UserDataStorage | null {
    try {
      const encryptedData = localStorage.getItem(USER_DATA);
      return encryptedData ? JSON.parse(decriptografar(encryptedData)) : null;
    } catch (error) {
      console.error('Error retrieving user data', error);
      return null;
    }
  },

  setUserDataLocalStorage(userData: UserDataStorage): void {
    try {
      localStorage.setItem(USER_DATA, criptografar(JSON.stringify(userData)));
    } catch (error) {
      console.error('Error storing user data', error);
    }
  },

  removeToken(): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_DATA);
  },

  async checkUserStatusByCpf(cpf: string): Promise<{ success: boolean; status: AuthStatus; userInfo?: UserDataStorage; message?: string }> {
    try {
      const internalUserResult = await getUserByCpf(cpf);

      const userStatusList = internalUserResult.statusList;

      if (userStatusList.includes('REGISTER_BLOCKED')) {
        return { success: false, status: AuthStatus.userBlocked, message: 'User is blocked' };
      }

      if (userStatusList.includes('REGISTER_INACTIVE')) {
        return { success: false, status: AuthStatus.userInactive, message: 'User is inactive' };
      }

      const userDataStorage: UserDataStorage = {
        uuid: internalUserResult.uuid,
        name: internalUserResult.name,
        cpf: internalUserResult.cpf,
        email: internalUserResult.email,
        blockRegistration: internalUserResult.blockRegistration,
        activeRegistration: internalUserResult.activeRegistration,
        profile: internalUserResult.profile.name,
        hasAcceptedTerms: internalUserResult.hasAcceptedTerms,
      };

      return { success: true, status: AuthStatus.userAuthorized, userInfo: userDataStorage };
    } catch (error: any) {
      if (error.response?.data?.statusCode === 404) {
        return { success: false, status: AuthStatus.userNotFound, message: 'User not found' };
      }
      return { success: false, status: AuthStatus.requestError, message: 'Request error' };
    }
  },
};
