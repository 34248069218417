import { AdressDto } from 'common/dtos/address.dto';
import { GroupDto } from 'common/dtos/group.dto';
import { ProfileDto } from 'common/dtos/profile.dto';
import { StatusDto } from 'common/dtos/status.dto';
import { ContactDto } from 'common/dtos/contact.dto';
import { ErrorDto } from 'common/dtos/error.dto';
import { InstitutionStatusEnum } from 'common/enums';

export interface IModel {
  state: IState;
  setInstitution: (institution: IStateInstitution) => void;
  setGroupList: (groupList: GroupDto[]) => void;
  setProfileList: (profileList: ProfileDto[]) => void;
  setStatusList: (statusList: StatusDto[]) => void;
  setToAddStatusList: (statusDescription: InstitutionStatusEnum) => void;
  setToDelStatusList: (statusDescription: InstitutionStatusEnum) => void;
  setError: (error: ErrorDto | null) => void;
  setIncreaseStep: () => void;
  setDecreaseStep: () => void;
  setHasError: (hasError: boolean) => void;
  setHasFinishInstitutionUpdate: (hasFinishInstitutionUpdate: boolean) => void;
  setStateOnFormikChange: (field: string, value: any) => any;
}

export interface IState {
  institution: IStateInstitution;
  groupList: GroupDto[];
  profileList: ProfileDto[];
  statusList: StatusDto[];
  toAddStatusList: StatusDto[];
  toDelStatusList: StatusDto[];
  error: ErrorDto | null;
  step: number;
  hasError: boolean;
  hasFinishInstitutionUpdate: boolean;
}

export interface IStateInstitution {
  uuid: string;
  name: string;
  fantasyName: string;
  identification: string;
  caf: string | null;
  email: string;
  address: AdressDto;
  group: GroupDto;
  profile: ProfileDto;
  contactList: ContactDto[];
  statusList: StatusDto[];
}

export const minStep = 1;
export const maxStep = 2;

export interface IInstitutionFormStepOne {
  state: IState;
}
