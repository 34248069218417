import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, Typography, Accordion, AccordionSummary, AccordionDetails, Box, IconButton, Divider } from '@mui/material';
import { CustomLabel } from 'components/Shared';

const labelStyle = {
  color: '#00000099',
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20.02px',
  letterSpacing: '0.17px',
  textAlign: 'left',
};

const valueStyle = {
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  textAlign: 'left',
};

interface PersonalDataDisplayProps {
  values: {
    name: string;
    cpf: string;
    email: string;
    phone: string;
    position: string;
    occupation: string;
    municipality: string;
  };
  onEditClick?: () => void;
  isView: boolean;
}

const PersonalDataDisplay: React.FC<PersonalDataDisplayProps> = ({ values, onEditClick, isView }) => {
  const [expanded, setExpanded] = useState(true);

  const handleAccordionToggle = () => {
    setExpanded(!expanded);
  };

  const handleEditClick = (event: React.MouseEvent) => {
    if (onEditClick) {
      event.stopPropagation();
      onEditClick();
    }
  };

  return (
    <Box sx={{ width: '1152px', marginTop: '20px' }}>
      <Accordion expanded={expanded} onChange={handleAccordionToggle}>
        <AccordionSummary expandIcon={null} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'nowrap',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <IconButton
                onClick={handleAccordionToggle}
                sx={{
                  marginRight: '10px',
                  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease-in-out',
                }}
              >
                <ExpandMoreIcon />
              </IconButton>

              <Typography variant='h6'>Dados pessoais</Typography>
              {!isView && (
                <Button
                  startIcon={<EditOutlinedIcon />}
                  variant='text'
                  sx={{ marginLeft: 'auto', textTransform: 'none', color: '#0048B6' }}
                  onClick={handleEditClick}
                >
                  Editar
                </Button>
              )}
            </Box>
            {expanded && (
              <Box sx={{ width: '100%' }}>
                <Divider sx={{ border: '1px solid #0000001F' }} />
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
            {/* Bloco de Nome e CPF */}
            <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '520px' }}>
                <CustomLabel text='Nome Completo' labelStyle={labelStyle} />
                <CustomLabel text={values.name} labelStyle={valueStyle} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '520px' }}>
                <CustomLabel text='CPF' labelStyle={labelStyle} />
                <CustomLabel text={values.cpf} labelStyle={valueStyle} />
              </Box>
            </Box>
            {/* Bloco de E-mail e Telefone */}
            <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '520px' }}>
                <CustomLabel text='E-mail' labelStyle={labelStyle} />
                <CustomLabel text={values.email} labelStyle={valueStyle} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '520px' }}>
                <CustomLabel text='Telefone' labelStyle={labelStyle} />
                <CustomLabel text={values.phone} labelStyle={valueStyle} />
              </Box>
            </Box>
            {/* Bloco de Cargo e Função */}
            <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '520px' }}>
                <CustomLabel text='Cargo' labelStyle={labelStyle} />
                <CustomLabel text={values.position} labelStyle={valueStyle} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '520px' }}>
                <CustomLabel text='Função' labelStyle={labelStyle} />
                <CustomLabel text={values.occupation} labelStyle={valueStyle} />
              </Box>
            </Box>
            {/* Bloco de Município*/}
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '520px' }}>
                <CustomLabel text='Município' labelStyle={labelStyle} />
                <CustomLabel text={values.municipality} labelStyle={valueStyle} />
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default PersonalDataDisplay;
