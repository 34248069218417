import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ImgBusca from '../../assets/images/img-busca.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const EmptyContent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = () => {
    if (location.pathname === '/usuarios') {
      navigate(ROUTES.MENU_USER);
    } else if (location.pathname === '/usuarios/cadastrar') {
      navigate(ROUTES.MANAGE_USER);
    } else if (location.pathname.startsWith('/usuarios/visualizar/')) {
      navigate(ROUTES.MANAGE_USER);
    } else {
      navigate(ROUTES.HOME);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#FAFAFA',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={ImgBusca} alt='No content available' style={{ maxHeight: '100%', objectFit: 'contain' }} />
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '38.4px',
            letterSpacing: '0.15px',
            textAlign: 'center',
            color: '#000000DE',
            marginTop: '15px',
          }}
        >
          Nenhuma informação para ser exibida no momento.
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '25.74px',
            letterSpacing: '0.17px',
            textAlign: 'center',
            color: '#00000099',
            marginTop: '10px',
          }}
        >
          O sistema não localizou nenhuma informação relacionada aos critérios de busca informados.
        </Typography>
        <Button variant='contained' onClick={handleBackClick} sx={{ marginTop: '20px', backgroundColor: '#0048B6', width: '328px' }}>
          voltar
        </Button>
      </Box>
    </Box>
  );
};

export default EmptyContent;
