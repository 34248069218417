import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { TopNavBar } from 'components/TopNavBar';
import MenuIcon from '@mui/icons-material/Menu';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PainelLogoImage from '../../assets/images/painel-logo-image.png';
import { Header, MainCard, UserInfo } from 'components/Home';
import { MenuProvider } from 'contexts/menu/MenuContext';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const PainelPage: React.FC = () => {
  const [value, setValue] = useState(0);

  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <MenuProvider>
      <>
        <TopNavBar />
        <Box
          sx={{
            backgroundColor: '#0048B6',
            marginTop: '10px',
            height: '65px',
            alignContent: 'center',
          }}
        >
          <Header title='E-SEIAF - Sistema Estadual Integrado da Agricultura Familiar' />
          <Box
            sx={{
              backgroundColor: '#0048B6',
              height: '190px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              marginTop: '25px',
            }}
          >
            <Box
              sx={{
                width: '1152px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  TabIndicatorProps={{
                    style: { backgroundColor: '#FFFFFF' },
                  }}
                >
                  <Tab
                    icon={<MenuIcon sx={{ color: '#FFFFFF' }} />}
                    label='MENU'
                    iconPosition='start'
                    sx={{
                      color: '#FFFFFF',
                      '&.Mui-selected': {
                        color: '#FFFFFF',
                      },
                    }}
                  />
                  <Tab
                    icon={<LeaderboardIcon sx={{ color: '#FFFFFF' }} />}
                    label='DASHBOARD'
                    iconPosition='start'
                    sx={{
                      color: '#FFFFFF',
                      '&.Mui-selected': {
                        color: '#FFFFFF',
                      },
                    }}
                  />
                </Tabs>
                <UserInfo />
              </Box>
              <Box sx={{ borderRadius: '16px', backgroundColor: 'FAFAFA' }}>
                <Box>
                  <img src={PainelLogoImage} alt='Sistema Estadual Integrado da Agricultura Familiar' />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '20px',
                      fontWeight: 500,
                      lineHeight: '32px',
                      letterSpacing: '0.15px',
                      textAlign: 'center',
                    }}
                  >
                    Gestão de Usuários
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '20.02px',
                      letterSpacing: '0.17px',
                      textAlign: 'center',
                      color: '#00000099',
                    }}
                  >
                    Crie, altere, exclua, e visualize todos os dados dos usuários do sistema.
                  </Typography>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      paddingTop: '10px',
                    }}
                  >
                    <MainCard chips={['Usuários', 'Perfis', 'Termos de Adesão']} onAccess={() => navigate(ROUTES.MENU_USER)} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    </MenuProvider>
  );
};

export default PainelPage;
