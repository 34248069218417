import React from 'react';
import { Box, Typography } from '@mui/material';
import { useAuth } from 'contexts/auth/AuthContext';

const UserInfo: React.FC = () => {
  const { user } = useAuth();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'flex-end',
      }}
    >
      <Typography variant='h6' sx={{ color: '#FFFFFF' }}>
        Olá, {user?.name}
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Roboto',
          fontWeight: 300,
          fontStyle: 'italic',
          fontSize: '14px',
          lineHeight: '12px',
          letterSpacing: '0.15px',
          color: '#FFFFFF',
        }}
      >
        Matrícula: 123456789
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Roboto',
          fontWeight: 300,
          fontStyle: 'italic',
          fontSize: '14px',
          lineHeight: '12px',
          letterSpacing: '0.15px',
          color: '#FFFFFF',
        }}
      >
        Perfil: {user?.profile}
      </Typography>
    </Box>
  );
};

export default UserInfo;
