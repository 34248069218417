import { Button, Checkbox, Chip, Pagination, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Header } from 'components/Home';
import { TopNavBar } from 'components/TopNavBar';
import { MenuProvider } from 'contexts/menu/MenuContext';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { OrderDialog } from 'components/Shared';
import { useQuery } from 'react-query';
import { ListDropdown } from 'api/common.types';
import { getProfilesDropdown } from 'api/profile/profile.api';
import { getAllInstitution } from 'api/institution/institution.api';
import InstitutionCard from 'components/Institution/InstitutionCard';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FiltersDialog from 'components/Institution/FiltersDialog';
import { DEFAULT_PAGINATION_QUERY, INSTITUTION_FILTER_STORAGE } from 'api/common.const';
import { GetAllInstitutionQuery } from 'api/institution/dtos/get-all-institution.query.dto';

const Institution: React.FC = () => {
  const loadFiltersFromLocalStorage = () => {
    const storagedFilters = localStorage.getItem(INSTITUTION_FILTER_STORAGE);
    return storagedFilters ? JSON.parse(storagedFilters) : DEFAULT_PAGINATION_QUERY;
  };

  const [filters, setFilters] = useState<Partial<GetAllInstitutionQuery>>(() => {
    const filter: Partial<GetAllInstitutionQuery> = loadFiltersFromLocalStorage();
    return filter;
  });
  const [orderBy, setOrderBy] = useState<string>('DESC');
  const [toggleOrderDialog, setToggleOrderDialog] = useState(false);
  const [toggleFiltersDialog, setToggleFiltersDialog] = useState(false);
  const [profilesDropdown, setProfilesDropdown] = useState<ListDropdown[]>([]);
  const [selectedInstitutions, setSelectedInstitutions] = useState<string[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const fetchInstitutionList = async () => {
    try {
      const result = await getAllInstitution(filters);
      const totalPages = Math.ceil(result.info.count / parseInt(filters.take!, 10));

      setTotalPages(totalPages);
      return result;
    } catch (error) {
      console.error('Error fetching users:', error);
      setInvalidRequest(true);
      return null;
    }
  };

  const { data: response } = useQuery(['institutionList', filters], fetchInstitutionList, {
    enabled: !!filters,
    keepPreviousData: true,
    retry: 1,
  });

  const applyFilters = async (newFilters: Partial<GetAllInstitutionQuery>) => {
    try {
      const result = await getAllInstitution(newFilters);

      if (result && result.info.count > 0) {
        localStorage.setItem(INSTITUTION_FILTER_STORAGE, JSON.stringify(newFilters));
        setFilters(newFilters);
        return true;
      }

      return false;
    } catch (error) {
      console.error('Error applying filters:', error);
      setInvalidRequest(true);
      return false;
    }
  };

  const hasAppliedFilters = () => {
    const { identification, name, profileUuid, city } = filters;
    return !!(identification || name || profileUuid || city);
  };

  const handleClearFilters = () => {
    localStorage.setItem(INSTITUTION_FILTER_STORAGE, JSON.stringify(DEFAULT_PAGINATION_QUERY));
    setFilters(DEFAULT_PAGINATION_QUERY);
  };

  const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const newFilters: Partial<GetAllInstitutionQuery> = { ...filters, orderBy: value };
    localStorage.setItem(INSTITUTION_FILTER_STORAGE, JSON.stringify(newFilters));
    setOrderBy(value);
  };

  const applySort = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      orderBy,
    }));
    setToggleOrderDialog(false);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && response?.data) {
      const intitutionIdList = response?.data?.map((user: any) => user.uuid);
      setSelectedInstitutions(intitutionIdList);
    } else {
      setSelectedInstitutions([]);
    }
  };

  useEffect(() => {
    const savedFilters = loadFiltersFromLocalStorage();
    if (savedFilters) {
      setFilters(savedFilters);
    }

    const fetchDropdowns = async () => {
      setProfilesDropdown(await getProfilesDropdown());
    };

    fetchDropdowns();
  }, []);

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page: String(page),
    }));
  };

  /////////////////////////////////////////////////////

  const navigate = useNavigate();

  const [invalidRequest, setInvalidRequest] = useState(false);

  const [blockingInstitutionIdList, setBlockingInstitutionIdList] = useState<string[]>([]);
  const [toggleConfirmInstitutionBlockingDialog, setToggleConfirmInstitutionBlockingDialog] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState('');

  const handleSelectInstitution = (institutionId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedInstitutions((prevSelected) => (event.target.checked ? [...prevSelected, institutionId] : prevSelected.filter((id) => id !== institutionId)));
  };

  // const handleToggleBlockingDialog = (userIds: string[]) => {
  //   const institutionToBlockList = response.users
  //     .filter((user: any) => userIds.includes(user.uuid) && user.status !== 'bloqueado')
  //     .map((user: any) => user.uuid);

  //   if (institutionToBlockList.length > 0) {
  //     setBlockingInstitutionIdList(institutionToBlockList);
  //     setToggleConfirmInstitutionBlockingDialog(true);
  //   }
  // };

  const handleToggleOrderDialog = () => {
    setToggleOrderDialog(!toggleOrderDialog);
  };

  const handleToggleFiltersDialog = () => {
    setToggleFiltersDialog(!toggleFiltersDialog);
  };

  return (
    <MenuProvider>
      <>
        <TopNavBar />
        <Box sx={{ backgroundColor: '#0048B6', marginTop: '10px', height: '65px', alignContent: 'center' }}>
          <Header title='Gerenciar Instituições' showBackButton={true} />
          <Box sx={{ backgroundColor: '#0048B6', height: '190px', display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
            <Box sx={{ width: '1552px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Chip
                    label='Ordernar'
                    onClick={handleToggleOrderDialog}
                    onDelete={handleToggleOrderDialog}
                    deleteIcon={<ArrowDropDownIcon style={{ color: '#FFFFFF' }} />}
                    variant='outlined'
                    sx={{ color: '#FFFFFF', border: '1px solid #FFFFFF', marginRight: '10px' }}
                  />
                  <Chip
                    label='Filtrar'
                    onClick={handleToggleFiltersDialog}
                    onDelete={handleToggleFiltersDialog}
                    deleteIcon={<ArrowDropDownIcon style={{ color: hasAppliedFilters() ? '#0048B6' : '#FFFFFF' }} />}
                    variant='outlined'
                    sx={{
                      color: hasAppliedFilters() ? '#0048B6' : '#FFFFFF',
                      backgroundColor: hasAppliedFilters() ? '#FFFFFF' : '#0048B6',
                      border: '1px solid #FFFFFF',
                    }}
                  />
                  {hasAppliedFilters() && (
                    <Button variant='text' startIcon={<CloseIcon />} sx={{ color: '#FFFFFF', marginLeft: '10px' }} onClick={handleClearFilters}>
                      Limpar filtros
                    </Button>
                  )}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: '10px' }}>
                <Checkbox sx={{ color: '#FFFFFF' }} checked={selectedInstitutions.length === (response?.data?.length || 0)} onChange={handleSelectAll} />

                <Typography sx={{ color: '#FFFFFF', fontSize: '18px', lineHeight: '27px' }}>
                  Selecionar todos |{selectedInstitutions.length} de {response?.data?.length || 0} selecionado(s)
                </Typography>
              </Box>
              {response?.data?.map((institution) => {
                return (
                  <InstitutionCard
                    key={institution.uuid}
                    uuid={institution.uuid}
                    profile={institution.profileName}
                    city={institution.city}
                    name={institution.name}
                    statusList={institution.statusList}
                    fantasyName={institution.fantasyName}
                    identification={institution.identification}
                    isChecked={selectedInstitutions.includes(institution.uuid)}
                    onCheckboxChange={handleSelectInstitution(institution.uuid)}
                    onBlockUser={() => {}}
                    // onBlockUser={() => handleToggleBlockingDialog([institution.uuid])}
                    // blockDisabled={institution.statusList === 'bloqueado'}
                    blockDisabled={false}
                  />
                );
              })}

              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
                <Pagination count={totalPages} page={currentPage} color='primary' onChange={handlePageChange} />
              </Box>
            </Box>
          </Box>
        </Box>

        <OrderDialog
          open={toggleOrderDialog}
          selectedSort={orderBy}
          onClose={() => setToggleOrderDialog(false)}
          handleSortChange={handleSortChange}
          applySort={applySort}
        />
      </>
      <FiltersDialog
        open={toggleFiltersDialog}
        onClose={() => setToggleFiltersDialog(false)}
        initialFilter={filters}
        applyFilters={applyFilters}
        profilesDropdown={profilesDropdown}
      />

      {/* <Dialog open={toggleConfirmUserBlockingDialog} onClose={handleCloseBlockingDialog} fullWidth maxWidth='md'>
        <DialogTitle sx={{ textAlign: 'center' }}>
          <img src={LockRedIcon} alt='Lock Icon' />
          <Typography sx={{ fontSize: '24px', marginTop: '20px' }}>Bloquear Usuário(s)</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ textAlign: 'center' }}>Tem certeza que você deseja bloquear o(s) Usuário(s)?</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', marginBottom: '30px' }}>
          <Button variant='contained' onClick={handleBlockUsers} sx={{ backgroundColor: '#D32F2F', '&:hover': { backgroundColor: '#D32F2F' } }}>
            CONFIRMAR BLOQUEIO
          </Button>
          <Button onClick={handleCloseBlockingDialog}>VOLTAR</Button>
        </DialogActions>
      </Dialog> */}
      {/* <ErrorDialog
        open={invalidRequest}
        title='Ocorreu um erro na sua solicitação'
        message1='Deseja atualizar a página atual?'
        button1Text='FECHAR'
        button1Action={handleCloseDialog}
        button1Color='inherit'
        button2Text='ATUALIZAR'
        button2Action={handleRefresh}
        button2Color='primary'
      /> */}
    </MenuProvider>
  );
};

export default Institution;
