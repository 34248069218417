import axios from 'axios';

const BASE_API_URL = process.env.REACT_APP_URL_API ?? '';
const API_URL = BASE_API_URL + '/groups';

export const getGroupsDropdown = async (): Promise<any> => {
  const response = await axios.get(`${API_URL}/dropdown`);

  return response.data;
};
