import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Header } from 'components/Home';
import { DynamicDialog } from 'components/Shared';
import { TopNavBar } from 'components/TopNavBar';
import UserFormSteps from 'components/User/UserFormSteps';
import { MenuProvider } from 'contexts/menu/MenuContext';
import { useEffect } from 'react';
import useModel from './model.form';
import { getAllStatus, getByIdInstitution, updateByIdInstitution } from 'api/institution/institution.api';
import { useParams } from 'react-router-dom';
import { getGroupsDropdown } from 'api/group/group.api';
import { getProfilesDropdown } from 'api/profile/profile.api';
import { ROUTES } from 'routes/constants';
import IconErrorNetwork from '../../../assets/icons/error_network.svg';
import IconUpdated from '../../../assets/icons/updated-icon.svg';
import {
  MSG_DATA_ERROR,
  MSG_GROUP_ID_NOT_FOUND,
  MSG_INSTITUTION_ID_NOT_FOUND,
  MSG_FINISH,
  MSG_INSTITUTION_UPDATED,
  MSG_INSTITUTION_WAS_UPDATED,
} from 'common/messages';
import { ErrorDto } from 'common/dtos/error.dto';
import { maxStep, minStep } from './types.form';
import InstitutionFormStepOne from './institution.form.step-one';
import CustomButton from 'components/Shared/CustomButton';
import InstitutionFormStepTwo from './institution.form.step-two';

const InstitutionForm: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const {
    state,
    setInstitution,
    setGroupList,
    setProfileList,
    setStatusList,
    setToAddStatusList,
    setToDelStatusList,
    setError,
    setIncreaseStep,
    setDecreaseStep,
    setHasError,
    setHasFinishInstitutionUpdate,
    setStateOnFormikChange,
  } = useModel();

  const fetchGetData = async () => {
    setError(null);

    if (!uuid) {
      setError({ title: MSG_DATA_ERROR, message: MSG_INSTITUTION_ID_NOT_FOUND });
      return;
    }

    try {
      const [institution, groupList, statusList] = await Promise.all([getByIdInstitution(uuid), getGroupsDropdown(), getAllStatus()]);

      setInstitution(institution);
      setGroupList(groupList);
      setStatusList(statusList);
    } catch (error) {
      const err = error as ErrorDto;
      console.error('err:', err);
      setError(err);
    }
  };

  const fetchGetAllProfile = async () => {
    setError(null);

    if (!!state.institution.uuid && !state.institution.group.uuid) {
      setError({ title: MSG_DATA_ERROR, message: MSG_GROUP_ID_NOT_FOUND });
      return;
    }

    try {
      const profileList = await getProfilesDropdown(state.institution.group.uuid);
      setProfileList(profileList);
    } catch (error) {
      const err = error as ErrorDto;
      console.error('err:', err);
      setError(err);
    }
  };

  const fetchUpdate = async () => {
    setError(null);

    if (!uuid) {
      setError({ title: MSG_DATA_ERROR, message: MSG_INSTITUTION_ID_NOT_FOUND });
      return;
    }

    try {
      console.log(state);
      await updateByIdInstitution(uuid as string, state);
      setHasFinishInstitutionUpdate(true);
    } catch (error) {
      const err = error as ErrorDto;
      console.error('err:', err);
      setError(err);
    }
  };

  useEffect(() => {
    fetchGetData();
  }, [uuid]);

  useEffect(() => {
    fetchGetAllProfile();
  }, [state.institution.group.uuid]);

  return (
    <>
      <MenuProvider>
        <TopNavBar />
        <Box sx={{ backgroundColor: '#0048B6', marginTop: '10px', height: '65px', alignContent: 'center' }}>
          <Header title='Editar Instituição' showBackButton={true} />
          <Box
            sx={{
              backgroundColor: '#FAFAFA',
            }}
          >
            <UserFormSteps activeStep={state.step} />

            <Box
              sx={{
                height: '79px',
                gap: '24px',
              }}
            >
              <Typography variant='h5'>{state.step === 1 ? 'Dados principais' : 'Confirmação dos dados'}</Typography>

              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '22.88px',
                  letterSpacing: '0.17px',
                  textAlign: 'center',
                  marginTop: '20px',
                }}
              >
                {state.step === 1
                  ? 'Informe corretamente os dados principais da instituição cadastrada.'
                  : 'Confira todas as informações que foram preenchidas, e altere-as caso necessário.'}
              </Typography>
            </Box>
          </Box>

          {state.step === 1 && (
            <InstitutionFormStepOne
              state={state}
              setToAddStatusList={setToAddStatusList}
              setToDelStatusList={setToDelStatusList}
              setHasError={setHasError}
              setStateOnFormikChange={setStateOnFormikChange}
            />
          )}

          {state.step === 2 && <InstitutionFormStepTwo state={state} />}

          <Box sx={{ mt: '16px', display: 'flex', justifyContent: 'center' }}>
            {state.step !== minStep && (
              <Box paddingX='16px'>
                <CustomButton name='back' onAction={setDecreaseStep} />
              </Box>
            )}

            {state.step !== maxStep && (
              <Box paddingX='16px'>
                <CustomButton name='next' onAction={setIncreaseStep} isDisabled={state.hasError} />
              </Box>
            )}

            {state.step === maxStep && (
              <Box paddingX='16px'>
                <CustomButton name='submit' onAction={() => fetchUpdate()} />
              </Box>
            )}
          </Box>

          <DynamicDialog
            isOpen={!!state.error || state.hasFinishInstitutionUpdate}
            icon={(() => {
              if (state.error) return IconErrorNetwork;
              else if (state.hasFinishInstitutionUpdate) return IconUpdated;
              return '//TODO: Icone generico.';
            })()}
            title={(() => {
              if (state.error) return state.error?.title ?? state.error?.code;
              else if (state.hasFinishInstitutionUpdate) return MSG_INSTITUTION_UPDATED;
              return '//TODO: Titulo generico.';
            })()}
            message={(() => {
              if (state.error) return state.error?.message;
              else if (state.hasFinishInstitutionUpdate) return MSG_INSTITUTION_WAS_UPDATED;
              return '//TODO: Mensagem generica.';
            })()}
            buttonText={MSG_FINISH.toUpperCase()}
            onClose={() => setError(null)}
            routeOnClose={(() => {
              if (state.hasFinishInstitutionUpdate) return ROUTES.INSTITUTION;
            })()}
          />
        </Box>
      </MenuProvider>
    </>
  );
};

export default InstitutionForm;
