import { TextField } from '@mui/material';
import { styled } from '@mui/system';

const CustomTextField = styled(TextField)(() => ({
  textTransform: 'uppercase',
  flex: 1,
  '& .MuiInputBase-input': {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textAlign: 'left',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.15px',
    textAlign: 'left',
    color: '#00000099',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    padding: '0px',
    margin: '0px',
    '& fieldset': {
      borderColor: '#0000003B',
    },
    '&:hover fieldset': {
      borderColor: '#0000003B',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#0000003B',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#00000099',
  },
}));

export default CustomTextField;
