import React from 'react';
import { Box, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled, Typography } from '@mui/material';
import stepsBallCircledBlueIcon from '../../assets/icons/steps-ball-circled-blue.svg';
import stepsBallGreyIcon from '../../assets/icons/steps-ball-grey.svg';
import stepsBallGreenCheckdIcon from '../../assets/icons/steps-ball-green-checkd.svg';

enum ActiveStepStatus {
  Pending = 'pendente',
  ToFill = 'a preencher',
  Filled = 'preenchido',
}

const statusColors: Record<ActiveStepStatus, string> = {
  [ActiveStepStatus.Pending]: '#00000099',
  [ActiveStepStatus.ToFill]: '#0048B6',
  [ActiveStepStatus.Filled]: '#2E7D32',
};

const steps = [
  { stepId: 1, step: 'PASSO 1', title: 'Dados principais' },
  { stepId: 2, step: 'PASSO 2', title: 'Confirmação dos dados' },
];

const getStepStatus = (stepId: number, activeStep: number): ActiveStepStatus => {
  if (stepId < activeStep) {
    return ActiveStepStatus.Filled;
  } else if (stepId === activeStep) {
    return ActiveStepStatus.ToFill;
  } else {
    return ActiveStepStatus.Pending;
  }
};

const CustomStepIcon: React.FC<{ status: ActiveStepStatus }> = ({ status }) => {
  let iconSrc;

  switch (status) {
    case ActiveStepStatus.Filled:
      iconSrc = stepsBallGreenCheckdIcon;
      break;
    case ActiveStepStatus.ToFill:
      iconSrc = stepsBallCircledBlueIcon;
      break;
    case ActiveStepStatus.Pending:
    default:
      iconSrc = stepsBallGreyIcon;
      break;
  }

  return (
    <Box
      sx={{
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={iconSrc} alt='step icon' style={{ width: '100%', height: '100%' }} />
    </Box>
  );
};

const CustomStepConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    '& .MuiStepConnector-line': {
      borderColor: '#0048B6',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    '& .MuiStepConnector-line': {
      borderColor: '#2E7D32',
    },
  },
  '& .MuiStepConnector-line': {
    height: '2px',
    borderColor: '#2E7D32',
    borderTopWidth: 2,
  },
}));

interface UserFormStepsProps {
  activeStep: number;
}

const UserFormSteps: React.FC<UserFormStepsProps> = ({ activeStep }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        background: '#FAFAFA',
        padding: '20px 0',
      }}
    >
      <Box
        sx={{
          width: '1152px',
          padding: '8px 0px 9px 0px',
          background: '#FFFFFF',
          marginTop: '50px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Stepper activeStep={activeStep} alternativeLabel connector={<CustomStepConnector />}>
          {steps.map((step) => {
            const status = getStepStatus(step.stepId, activeStep);
            return (
              <Step key={step.stepId} completed={status === ActiveStepStatus.Filled} sx={{ width: '220px' }}>
                <StepLabel StepIconComponent={() => <CustomStepIcon status={status} />}>
                  <Box
                    sx={{
                      textAlign: 'left',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography variant='overline'>{step.step}</Typography>
                    <Typography variant='subtitle2'>{step.title}</Typography>
                    <Typography
                      variant='subtitle2'
                      sx={{
                        color: statusColors[status],
                        fontWeight: 400,
                      }}
                    >
                      {status}
                    </Typography>
                  </Box>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </Box>
  );
};

export default UserFormSteps;
