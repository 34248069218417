import React, { useEffect } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { userCardStyles, UserCardActions, UserCardStatus } from './index';
import { formatCPF } from 'services/utils/formatCPF';

interface UserCardProps {
  userUuid: string;
  name: string;
  cpf: string;
  city: string;
  group: string;
  profile: string;
  permission: string;
  status: string;
  isChecked: boolean;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlockUser: () => void;
  blockDisabled: boolean;
  openManageTermDialog: () => void;
  usersWithTermValidity: boolean;
}

const UserCard: React.FC<UserCardProps> = ({
  userUuid,
  name,
  cpf,
  city,
  group,
  profile,
  permission,
  status,
  isChecked,
  onCheckboxChange,
  onBlockUser,
  blockDisabled,
  openManageTermDialog,
  usersWithTermValidity,
}) => {
  useEffect(() => {}, [permission]);

  return (
    <Box sx={userCardStyles.container}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <Checkbox checked={isChecked} onChange={onCheckboxChange} />
        </Box>
        <Box sx={userCardStyles.details}>
          <Typography sx={{ color: '#000000DE', fontWeight: 500, fontSize: '18px', lineHeight: '28.26px', letterSpacing: '0.1px' }}>{name}</Typography>
          <Typography sx={{ color: '#00000099', fontWeight: 400, fontSize: '16px', lineHeight: '26.56px', letterSpacing: '0.4px' }}>{`CPF: ${formatCPF(
            cpf,
          )}`}</Typography>
          <Typography
            sx={{ color: '#00000099', fontWeight: 400, fontSize: '16px', lineHeight: '26.56px', letterSpacing: '0.4px' }}
          >{`Cidade: ${city}`}</Typography>
          <Typography
            sx={{ color: '#00000099', fontWeight: 400, fontSize: '16px', lineHeight: '26.56px', letterSpacing: '0.4px' }}
          >{`Grupo: ${group}`}</Typography>
          <Typography
            sx={{ color: '#00000099', fontWeight: 400, fontSize: '16px', lineHeight: '26.56px', letterSpacing: '0.4px' }}
          >{`Perfil: ${profile}`}</Typography>
          <Typography
            sx={{ color: '#00000099', fontWeight: 400, fontSize: '16px', lineHeight: '26.56px', letterSpacing: '0.4px' }}
          >{`Permissão: ${permission}`}</Typography>
        </Box>
        <UserCardActions
          onBlockUser={onBlockUser}
          blockDisabled={blockDisabled}
          userUuid={userUuid}
          openManageTermDialog={openManageTermDialog}
          usersWithTermValidity={usersWithTermValidity}
        />
      </Box>

      <UserCardStatus status={status} />
    </Box>
  );
};

export default UserCard;
