export const formatCep = (cep: string): string => {
  // Remove qualquer caractere que não seja número
  const onlyNumbers = cep.replace(/\D/g, '');

  // Formata o CEP no padrão brasileiro
  return onlyNumbers.replace(/(\d{5})(\d{3})/, '$1-$2');
};

export const validateCep = (cep: string): boolean => {
  // Aceita CEP vazio pois alguns lugares não possuem CEP.
  if (!cep.length) return true;

  // Remove qualquer caractere não numérico para garantir que o valor é só de números
  const onlyNumbers = cep.replace(/\D/g, '');

  // Verifica se tem exatamente 8 dígitos
  if (onlyNumbers.length !== 8) {
    return false;
  }

  // Valida o formato opcionalmente (99999-999)
  const regex = /^[0-9]{5}-?[0-9]{3}$/;
  return regex.test(cep);
};
