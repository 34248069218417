import { Autocomplete, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { Box, SxProps, Theme } from '@mui/system';
import CustomAccordion from 'components/Shared/CustomAccordion';
import { FieldArray, Formik } from 'formik';
import { formatCNPJ } from 'services/utils/cnpj.utils';
import formValidation from './validations.form';
import { formatCep } from 'services/utils/cep.utils';
import { ContactDto } from 'common/dtos/contact.dto';
import { formatPhoneNumber } from 'services/utils/cellphone.utils';
import { InstitutionStatusEnum } from 'common/enums';
import { MSG_HELPER_CELLPHONE } from 'common/messages';
import { IModel, IState } from './types.form';

const InstitutionFormStepOne: React.FC<Pick<IModel, 'state' | 'setToAddStatusList' | 'setToDelStatusList' | 'setHasError' | 'setStateOnFormikChange'>> = ({
  state,
  setToAddStatusList,
  setToDelStatusList,
  setHasError,
  setStateOnFormikChange,
}) => {
  const validate: any = (values: IState) => {
    return formValidation
      .validate(values, { abortEarly: false })
      .then(() => {
        setHasError(false);
      })
      .catch((err) => {
        const errors = err.inner.reduce((acc: any, validationError: any) => {
          acc[validationError.path] = validationError.message;
          return acc;
        }, {});

        setHasError(true);
        return errors;
      });
  };

  return (
    <Formik enableReinitialize onSubmit={() => {}} initialValues={state} validationSchema={formValidation} validate={validate}>
      {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => {
        return (
          <Box sx={{ mx: '32px', mt: '32px' }}>
            <CustomAccordion accordionSummaryText='Dados cadastrais'>
              <Box>
                <Box sx={lineStyle}>
                  <Box sx={columnStyle}>
                    <TextField
                      sx={fieldStyle}
                      name='institution.fantasyName'
                      label='Nome Fantasia'
                      variant='outlined'
                      size='small'
                      value={values.institution.fantasyName}
                      onChange={(e) => {
                        handleChange(e);
                        setStateOnFormikChange('institution.fantasyName', e.target.value);
                      }}
                      onBlur={handleBlur}
                      error={touched.institution?.fantasyName && !!errors.institution?.fantasyName}
                      helperText={touched.institution?.fantasyName && errors.institution?.fantasyName}
                    />
                  </Box>
                  <Box sx={columnStyle}>
                    <TextField
                      sx={fieldStyle}
                      name='institution.identification'
                      label='CNPJ'
                      variant='outlined'
                      size='small'
                      value={formatCNPJ(state.institution.identification)}
                      inputProps={{ maxLength: 18 }}
                      onChange={(e) => {
                        handleChange(e);
                        setStateOnFormikChange('institution.identification', e.target.value);
                      }}
                      onBlur={handleBlur}
                      error={touched.institution?.identification && !!errors.institution?.identification}
                      helperText={touched.institution?.identification && errors.institution?.identification}
                    />
                  </Box>
                </Box>
                <Box sx={lineStyle}>
                  <Box sx={columnStyle}>
                    <TextField
                      sx={fieldStyle}
                      name='institution.name'
                      label='Razão Social'
                      variant='outlined'
                      size='small'
                      value={values.institution.name}
                      onChange={(e) => {
                        handleChange(e);
                        setStateOnFormikChange('institution.name', e.target.value);
                      }}
                      onBlur={handleBlur}
                      error={touched.institution?.name && !!errors.institution?.name}
                      helperText={touched.institution?.name && errors.institution?.name}
                    />
                  </Box>
                  <Box sx={columnStyle}>
                    <TextField
                      sx={fieldStyle}
                      name='institution.address.cep'
                      label='CEP'
                      variant='outlined'
                      size='small'
                      value={formatCep(values.institution?.address.cep)}
                      inputProps={{ maxLength: 9 }}
                      onChange={(e) => {
                        handleChange(e);
                        setStateOnFormikChange('institution.address.cep', e.target.value);
                      }}
                      onBlur={handleBlur}
                      error={touched.institution?.address?.cep && !!errors.institution?.address?.cep}
                      helperText={touched.institution?.address?.cep && errors.institution?.address?.cep}
                    />
                  </Box>
                </Box>
                <Box sx={lineStyle}>
                  <Box sx={columnStyle}>
                    <TextField
                      sx={fieldStyle}
                      name='institution.address.street'
                      label='Endereço'
                      variant='outlined'
                      size='small'
                      value={values.institution.address.street}
                      onChange={(e) => {
                        handleChange(e);
                        setStateOnFormikChange('institution.address.street', e.target.value);
                      }}
                      onBlur={handleBlur}
                      error={touched.institution?.address?.street && !!errors.institution?.address?.street}
                      helperText={touched.institution?.address?.street && errors.institution?.address?.street}
                    />
                  </Box>
                  <Box sx={columnStyle}>
                    <TextField
                      sx={fieldStyle}
                      name='institution.address.number'
                      label='Número'
                      variant='outlined'
                      size='small'
                      value={values.institution?.address.number}
                      onChange={(e) => {
                        handleChange(e);
                        setStateOnFormikChange('institution.address.number', e.target.value);
                      }}
                      onBlur={handleBlur}
                      error={touched.institution?.address?.number && !!errors.institution?.address?.number}
                      helperText={touched.institution?.address?.number && errors.institution?.address?.number}
                    />
                  </Box>
                </Box>
                <Box sx={lineStyle}>
                  <Box sx={columnStyle}>
                    <TextField
                      sx={fieldStyle}
                      name='institution.email'
                      label='Email'
                      variant='outlined'
                      size='small'
                      value={values.institution.email}
                      onChange={(e) => {
                        handleChange(e);
                        setStateOnFormikChange('institution.email', e.target.value);
                      }}
                      onBlur={handleBlur}
                      error={touched.institution?.email && !!errors.institution?.email}
                      helperText={touched.institution?.email && errors.institution?.email}
                    />
                  </Box>
                </Box>
                <Box sx={lineStyle}>
                  <FieldArray
                    name='institution.contactList'
                    render={() => {
                      return values.institution.contactList.map((_, index) => (
                        <Box key={index} sx={columnStyle}>
                          <TextField
                            sx={fieldStyle}
                            inputProps={{ maxLength: 17 }}
                            label={`Telefone 0${index + 1}`}
                            name={`institution.contactList[${index}].value`}
                            variant='outlined'
                            size='small'
                            value={formatPhoneNumber(values.institution.contactList[index].value)}
                            onChange={(e) => {
                              setFieldValue(`institution.contactList[${index}].value`, e.target.value);
                              setStateOnFormikChange(`institution.contactList[${index}].value`, e.target.value);
                            }}
                            onBlur={handleBlur}
                            error={touched.institution?.contactList?.[index]?.value && !!(errors.institution?.contactList?.[index] as ContactDto)?.value}
                            helperText={
                              (touched.institution?.contactList?.[index]?.value && (errors.institution?.contactList?.[index] as ContactDto)?.value) ||
                              MSG_HELPER_CELLPHONE
                            }
                          />
                        </Box>
                      ));
                    }}
                  />
                </Box>
              </Box>
            </CustomAccordion>

            <CustomAccordion accordionSummaryText='Dados administrativos'>
              <Box>
                <Box sx={lineStyle}>
                  <Box sx={columnStyle}>
                    <Autocomplete
                      disablePortal
                      isOptionEqualToValue={(value, option) => option.id === value.id}
                      options={state.groupList.map((item) => ({ id: item.uuid, label: item.name, description: item.description }))}
                      value={{
                        id: values.institution.group.uuid,
                        label: values.institution.group.name,
                        description: values.institution.group.description,
                      }}
                      onChange={(_, newValue) => {
                        if (newValue) {
                          const data = {
                            uuid: newValue.id,
                            name: newValue.label,
                            description: newValue?.description as string,
                          };
                          setFieldValue('institution.group', data);
                          setStateOnFormikChange('institution.group', data);
                        }
                      }}
                      onBlur={handleBlur}
                      renderInput={(params) => <TextField {...params} label='Grupo da instituição' />}
                    />
                  </Box>
                  <Box sx={columnStyle}>
                    <Autocomplete
                      disablePortal
                      isOptionEqualToValue={(value, option) => option.id === value.id}
                      options={state.profileList.map((item) => ({ id: item.uuid, label: item.name, description: item.description }))}
                      value={{
                        id: values.institution.profile.uuid,
                        label: values.institution.profile.name,
                        description: values.institution.profile.description,
                      }}
                      onChange={(_, newValue) => {
                        if (newValue) {
                          const data = {
                            uuid: newValue.id,
                            name: newValue.label,
                            description: newValue.description,
                          };
                          setFieldValue('institution.profile', data);
                          setStateOnFormikChange('institution.profile', data);
                        }
                      }}
                      renderInput={(params) => <TextField {...params} label='Perfil da instituição' />}
                    />
                  </Box>
                </Box>

                <Box sx={lineStyle}>
                  <Box sx={columnStyle}>
                    <TextField
                      sx={fieldStyle}
                      disabled
                      label='Possui CAF juridica ativa (Obs.: Antiga DAP juridica)?'
                      value={values.institution.caf ? 'Sim' : 'Não'}
                    />
                  </Box>
                </Box>

                {!!values.institution.statusList.length && (
                  <Box sx={lineStyle}>
                    <Box sx={{ ...columnStyle, width: 'auto' }}>
                      <FormControl>
                        <FormLabel id='demo-radio-buttons-group-label'>Cadastro Ativo:</FormLabel>
                        <RadioGroup
                          aria-labelledby='demo-radio-buttons-group-label'
                          name='radio-buttons-group'
                          defaultValue={() => values.institution.statusList.some((item) => item.description === InstitutionStatusEnum.REGISTER_ENABLED)}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label='Sim'
                            onClick={() => setToAddStatusList(InstitutionStatusEnum.REGISTER_ENABLED)}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label='Não'
                            onClick={() => setToDelStatusList(InstitutionStatusEnum.REGISTER_ENABLED)}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ ...columnStyle, width: 'auto' }}>
                      <FormControl>
                        <FormLabel id='demo-radio-buttons-group-label'>Bloquear Cadastro:</FormLabel>
                        <RadioGroup
                          aria-labelledby='demo-radio-buttons-group-label'
                          name='radio-buttons-group'
                          defaultValue={() => values.institution.statusList.some((item) => item.description === InstitutionStatusEnum.REGISTER_BLOCKED)}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label='Sim'
                            onClick={() => setToAddStatusList(InstitutionStatusEnum.REGISTER_BLOCKED)}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label='Não'
                            onClick={() => setToDelStatusList(InstitutionStatusEnum.REGISTER_BLOCKED)}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                )}

                {!values.institution.statusList.length && (
                  <Box sx={lineStyle}>
                    <Box sx={{ ...columnStyle, width: 'auto' }}>
                      <FormControl>
                        <FormLabel id='demo-radio-buttons-group-label'>Cadastro Ativo:</FormLabel>
                        <RadioGroup
                          aria-labelledby='demo-radio-buttons-group-label'
                          name='radio-buttons-group'
                          defaultValue={() => values.institution.statusList.some((item) => item.description === InstitutionStatusEnum.REGISTER_ENABLED)}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label='Sim'
                            onClick={() => setToAddStatusList(InstitutionStatusEnum.REGISTER_ENABLED)}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label='Não'
                            onClick={() => setToDelStatusList(InstitutionStatusEnum.REGISTER_ENABLED)}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ ...columnStyle, width: 'auto' }}>
                      <FormControl>
                        <FormLabel id='demo-radio-buttons-group-label'>Bloquear Cadastro:</FormLabel>
                        <RadioGroup
                          aria-labelledby='demo-radio-buttons-group-label'
                          name='radio-buttons-group'
                          defaultValue={() => values.institution.statusList.some((item) => item.description === InstitutionStatusEnum.REGISTER_BLOCKED)}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label='Sim'
                            onClick={() => setToAddStatusList(InstitutionStatusEnum.REGISTER_BLOCKED)}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label='Não'
                            onClick={() => setToDelStatusList(InstitutionStatusEnum.REGISTER_BLOCKED)}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                )}
              </Box>
            </CustomAccordion>
          </Box>
        );
      }}
    </Formik>
  );
};

const lineStyle: SxProps<Theme> | undefined = {
  display: 'flex',
};

const columnStyle: SxProps<Theme> | undefined = {
  width: '50%',
  padding: '8px',
};

const fieldStyle: SxProps<Theme> | undefined = {
  width: '100%',
};

export default InstitutionFormStepOne;
