import { validateCellphone } from 'services/utils/cellphone.utils';
import { validateCep } from 'services/utils/cep.utils';
import { validateCNPJ } from 'services/utils/cnpj.utils';
import * as Yup from 'yup';

const formValidation = Yup.object().shape({
  institution: Yup.object().shape({
    name: Yup.string().required('Campo obrigatório.'),
    fantasyName: Yup.string().required('Campo obrigatório.'),
    identification: Yup.string()
      .required('Campo obrigatório.')
      .test('cnpj-valid', 'CNPJ inválido.', (value) => validateCNPJ(value ?? '')),
    email: Yup.string().required('Campo obrigatório.'),
    // caf: Yup.string().required('Preencha esse campo corretamente, com um dado válido.'),
    address: Yup.object().shape({
      cep: Yup.string().test('cep-valid', 'CEP inválido.', (value) => validateCep(value ?? '')),
      street: Yup.string().required('Campo obrigatório.'),
      number: Yup.string().required('Campo obrigatório.'),
    }),
    contactList: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string()
            .required('Campo obrigatório.')
            .test('valid-phone', 'Contato inválido.', (value) => validateCellphone(value ?? '')),
        }),
      )
      .min(1, 'Adicione pelo menos um (1) numero de telefone.'),
  }),
});

export default formValidation;
