import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, FormControl, FormControlLabel, FormLabel, Radio, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomAlert, CustomRadioGroup } from 'components/Shared';

import { UserFormAction } from 'pages/User/UserForm';

interface RegistrationDataFormProps {
  values: any;
  errors: any;
  touched: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  userFormAction: UserFormAction;
  permissionMandatoryValidityValidation: boolean;
}

const RegistrationDataForm: React.FC<RegistrationDataFormProps> = ({ values, errors, touched, handleChange }) => {
  const getErrorMessage = () => {
    if (errors.registrationData?.activeRegistration && touched.registrationData?.activeRegistration) {
      return 'Selecione a opção que deseja de "Cadastro Ativo" abaixo para prosseguir.';
    }
    if (errors.registrationData?.blockRegistration && touched.registrationData?.blockRegistration) {
      return 'Selecione a opção que deseja de "Bloquear Cadastro" abaixo para prosseguir.';
    }
    return null;
  };
  const errorMessage = getErrorMessage();

  return (
    <>
      <Box sx={{ width: '1152px', marginTop: '20px' }}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h6'>Dados do cadastro</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {errorMessage && <CustomAlert severity='error' message={errorMessage} />}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                <FormControl>
                  <FormLabel
                    id='activeRegistration'
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '22.88px',
                      letterSpacing: '0.17px',
                      textAlign: 'left',
                      color: '#00000099',
                      '&.Mui-focused': {
                        color: '#00000099',
                      },
                    }}
                  >
                    Cadastro Ativo:
                  </FormLabel>
                  <CustomRadioGroup
                    aria-labelledby='activeRegistration'
                    name='registrationData.activeRegistration'
                    value={values.registrationData.activeRegistration}
                    onChange={handleChange}
                  >
                    <FormControlLabel value={true} control={<Radio />} label='Sim' />
                    <FormControlLabel value={false} control={<Radio />} label='Não' />
                  </CustomRadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                <FormControl>
                  <FormLabel
                    id='blockRegistration'
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '22.88px',
                      letterSpacing: '0.17px',
                      textAlign: 'left',
                      color: '#00000099',
                      '&.Mui-focused': {
                        color: '#00000099',
                      },
                    }}
                  >
                    Bloquear Cadastro:
                  </FormLabel>
                  <CustomRadioGroup
                    aria-labelledby='blockRegistration'
                    name='registrationData.blockRegistration'
                    value={values.registrationData.blockRegistration}
                    onChange={handleChange}
                  >
                    <FormControlLabel value={true} control={<Radio />} label='Sim' />
                    <FormControlLabel value={false} control={<Radio />} label='Não' />
                  </CustomRadioGroup>
                </FormControl>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default RegistrationDataForm;
