import React from 'react';
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';

interface ModuleCardProps {
  title: string;
  description: string;
  image: string;
  path: string | null;
}

const ModuleCard: React.FC<ModuleCardProps> = ({ title, description, image, path }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <Card sx={{ height: '96px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'nowrap' }}>
      <CardActionArea
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingLeft: '20px',
        }}
        onClick={handleClick}
      >
        <CardMedia component='img' sx={{ height: '48px', width: '48px' }} image={image} alt='green iguana' />
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '0.15px',
              textAlign: 'left',
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20.02px',
              letterSpacing: '0.17px',
              textAlign: 'left',
            }}
          >
            {description}
          </Typography>
        </CardContent>

        <Box
          sx={{
            ml: 'auto',
            marginRight: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            transition: 'background-color 0.3s, transform 0.3s',
            '&:hover': {
              backgroundColor: '#f0f0f0',
              transform: 'scale(1.1)',
            },
          }}
        >
          <ChevronRightIcon sx={{ fontSize: '24px' }} />
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default ModuleCard;
