import { useState } from 'react';
import { IState, IModel, IStateInstitution, minStep, maxStep } from './types.form';
import { GroupDto } from 'common/dtos/group.dto';
import { ProfileDto } from 'common/dtos/profile.dto';
import { StatusDto } from 'common/dtos/status.dto';
import { ErrorDto } from 'common/dtos/error.dto';
import { InstitutionStatusEnum } from 'common/enums';

const useModel = (): IModel => {
  const [state, setState] = useState<IState>({
    institution: {
      uuid: '',
      name: '',
      fantasyName: '',
      identification: '',
      caf: '',
      email: '',
      address: {
        uuid: '',
        cep: '',
        city: '',
        district: '',
        street: '',
        number: '',
        complement: '',
      },
      group: {
        uuid: '',
        name: '',
        description: '',
      },
      profile: {
        uuid: '',
        name: '',
        description: '',
      },
      contactList: [],
      statusList: [],
    },
    groupList: [
      {
        uuid: '',
        name: '',
        description: '',
      },
    ],
    profileList: [
      {
        uuid: '',
        name: '',
        description: '',
      },
    ],
    statusList: [],
    toAddStatusList: [],
    toDelStatusList: [],
    error: null,
    step: 1,
    hasError: false,
    hasFinishInstitutionUpdate: false,
  });

  const setInstitution = (institution: IStateInstitution) => {
    setState((prevState) => ({ ...prevState, institution }));
  };

  const setGroupList = (groupList: GroupDto[]) => {
    setState((prevState) => ({ ...prevState, groupList }));
  };

  const setProfileList = (profileList: ProfileDto[]) => {
    setState((prevState) => ({ ...prevState, profileList }));
  };

  const setStatusList = (statusList: StatusDto[]) => {
    setState((prevState) => ({ ...prevState, statusList }));
  };

  const setToAddStatusList = (statusDescription: InstitutionStatusEnum) => {
    // Remove do vetor de status que seram removidos, o status que será adicionado.
    const toDelStatusList = state.toDelStatusList.filter((item) => item.description !== statusDescription);
    setState((prevState) => ({ ...prevState, toDelStatusList }));

    // Adiciona o status no vetor de status a serem aplicados, caso o status não esteja aplicado e não esteja na lista que será aplicada.
    const wasAdded = state.toAddStatusList.some((item) => item.description === statusDescription);
    const hasStatus = state.institution.statusList.some((item) => item.description === statusDescription);
    if (!wasAdded && !hasStatus) {
      const newStatus = state.statusList.find((item) => item.description === statusDescription);
      if (newStatus) setState((prevState) => ({ ...prevState, toAddStatusList: [...state.toAddStatusList, newStatus] }));
    }
  };

  const setToDelStatusList = (statusDescription: InstitutionStatusEnum) => {
    // Remove do vetor de status que seram aplicados, o status que será deletedo.
    const toAddStatusList = state.toAddStatusList.filter((item) => item.description !== statusDescription);
    setState((prevState) => ({ ...prevState, toAddStatusList }));

    // Adiciona o status no vetor de status a serem deletados, caso o status esteja aplicado e nao lista que será deletada.
    const wasAdded = state.toDelStatusList.some((item) => item.description === statusDescription);
    const hasStatus = state.institution.statusList.some((item) => item.description === statusDescription);
    if (!wasAdded && hasStatus) {
      const newStatus = state.statusList.find((item) => item.description === statusDescription);
      if (newStatus) setState((prevState) => ({ ...prevState, toDelStatusList: [...state.toDelStatusList, newStatus] }));
    }
  };

  const setError = (error: ErrorDto | null) => {
    setState((prevState) => ({ ...prevState, error }));
  };

  const setIncreaseStep = () => {
    const newStep = state.step + 1;
    if (newStep <= maxStep) setState((prevState) => ({ ...prevState, step: newStep }));
  };

  const setDecreaseStep = () => {
    const newStep = state.step - 1;
    if (newStep >= minStep) setState((prevState) => ({ ...prevState, step: newStep }));
  };

  const setHasError = (hasError: boolean) => {
    setState((prevState) => ({ ...prevState, hasError }));
  };

  const setHasFinishInstitutionUpdate = (hasFinishInstitutionUpdate: boolean) => {
    setState((prevState) => ({ ...prevState, hasFinishInstitutionUpdate }));
  };

  const setStateOnFormikChange = (field: string, value: any) => {
    setState((prevState) => {
      const newState = { ...prevState }; // Cria uma cópia do estado para evitar mutações

      // Divide o caminho do campo em partes, considerando chaves com índices de arrays
      const keys = field.split(/[.[\]]/).filter(Boolean); // Exemplo: "institution.contactList[0].value" -> ["institution", "contactList", "0", "value"]

      // Usa reduce para navegar até o último nível antes do campo desejado
      keys.reduce((acc: any, key: any, index) => {
        // Se o índice atual representa o último nível, definimos o valor
        if (index === keys.length - 1) {
          acc[key] = value;
        } else {
          // Converte a chave para número caso seja um índice de array
          const nextKey = isNaN(key) ? key : Number(key);

          // Se o próximo nível não existe, cria um objeto ou array vazio conforme necessário
          if (acc[nextKey] === undefined) {
            acc[nextKey] = typeof keys[index + 1] === 'number' ? [] : {};
          }

          // Retorna o próximo nível para o reduce
          return acc[nextKey];
        }
      }, newState);

      return newState;
    });
  };

  return {
    state,
    setInstitution,
    setToAddStatusList,
    setToDelStatusList,
    setGroupList,
    setProfileList,
    setStatusList,
    setError,
    setIncreaseStep,
    setDecreaseStep,
    setHasError,
    setHasFinishInstitutionUpdate,
    setStateOnFormikChange,
  };
};
export default useModel;
