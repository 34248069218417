import { Button, SxProps, Theme } from '@mui/material';
import { MSG_BACK, MSG_NEXT, MSG_RESET, MSG_SUBMIT } from 'common/messages';

interface IProps {
  name: 'submit' | 'reset' | 'button' | 'back' | 'next';
  onAction?: () => void;
  isDisabled?: boolean;
}

interface IData {
  text: string;
  type: 'submit' | 'reset' | 'button';
  style?: SxProps<Theme>;
}

const CustomButton: React.FC<IProps> = ({ name, onAction, isDisabled }) => {
  const observerName: { [key: string]: () => IData } = {
    submit: () => ({
      type: 'submit',
      text: MSG_SUBMIT.toUpperCase(),
      style: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: '24px',
        letterSpacing: '0.4px',
        textAlign: 'left',
        color: '#FFFFFF',
        background: isDisabled ? '#B0BEC5' : '#0048B6',
      },
    }),
    reset: () => ({ type: 'reset', text: MSG_RESET.toUpperCase() }),
    button: () => ({ type: 'button', text: 'BUTTON' }),
    next: () => ({
      type: 'button',
      text: MSG_NEXT.toUpperCase(),
      style: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: '24px',
        letterSpacing: '0.4px',
        textAlign: 'left',
        color: '#FFFFFF',
        background: isDisabled ? '#B0BEC5' : '#0048B6',
      },
    }),
    back: () => ({
      type: 'button',
      text: MSG_BACK.toUpperCase(),
      style: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: '24px',
        letterSpacing: '0.4px',
        textAlign: 'left',
        color: '#0048B6',
        marginRight: '20px',
      },
    }),
  };

  const data = observerName[name]();
  return (
    <Button sx={{ ...data.style }} type={data.type} onClick={onAction} disabled={isDisabled}>
      {data.text}
    </Button>
  );
};

export default CustomButton;
